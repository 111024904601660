import React from 'react';
import { Share } from 'lucide-react';

const ShareButton = ({ event, className = '' }) => {
  const eventUrl = `${window.location.origin}/e/${event.link_hash}`;

  const handleShare = async () => {
    // Check if the Web Share API is available (primarily for mobile devices)
    if (navigator.share) {
      try {
        await navigator.share({
          title: event.event_title,
          text: `Check out this event at ${event.venue_name}`,
          url: eventUrl
        });
      } catch (error) {
        if (error.name !== 'AbortError') {
          fallbackShare();
        }
      }
    } else {
      fallbackShare();
    }
  };

  const fallbackShare = () => {
    // Copy to clipboard fallback for desktop
    navigator.clipboard.writeText(eventUrl)
      .then(() => {
        // Show a toast or some feedback that the URL was copied
        alert('Event link copied to clipboard!');
      })
      .catch(() => {
        // If clipboard API fails, let them manually copy
        prompt('Copy this event link:', eventUrl);
      });
  };

  return (
    <button
      onClick={handleShare}
      className={`inline-flex items-center gap-2 text-[#a891f5] hover:text-[#a891f5]/80 transition-colors ${className}`}
      aria-label="event"
    >
      <span className="text-sm">share</span>
      <Share className="w-5 h-5" />
    </button>
  );
};

export default ShareButton;
