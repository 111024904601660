export const COLOR_CONFIG = {
  // Event Types
  FOOD: {
    name: 'emerald',
    badge: 'bg-emerald-900 text-emerald-200',
    filterSelected: 'bg-emerald-600 text-emerald-50',
    filterUnselected: 'bg-emerald-900/50 text-emerald-200 hover:bg-emerald-800'
  },
  CLOTHES: {
    name: 'pink',
    badge: 'bg-pink-900 text-pink-200',
    filterSelected: 'bg-pink-600 text-pink-50',
    filterUnselected: 'bg-pink-900/50 text-pink-200 hover:bg-pink-800'
  },
  ART: {
    name: 'rose',
    badge: 'bg-rose-900 text-rose-200',
    filterSelected: 'bg-rose-600 text-rose-50',
    filterUnselected: 'bg-rose-900/50 text-rose-200 hover:bg-rose-800'
  },
  MUSIC: {
    name: 'purple',
    badge: 'bg-purple-900 text-purple-200',
    filterSelected: 'bg-purple-600 text-purple-50',
    filterUnselected: 'bg-purple-900/50 text-purple-200 hover:bg-purple-800'
  },
  POLITICS: {
    name: 'red',
    badge: 'bg-red-900 text-red-200',
    filterSelected: 'bg-red-600 text-red-50',
    filterUnselected: 'bg-red-900/50 text-red-200 hover:bg-red-800'
  },
  EDUCATION: {
    name: 'blue',
    badge: 'bg-blue-900 text-blue-200',
    filterSelected: 'bg-blue-600 text-blue-50',
    filterUnselected: 'bg-blue-900/50 text-blue-200 hover:bg-blue-800'
  },
  MARKET: {
    name: 'lime',
    badge: 'bg-lime-900 text-lime-200',
    filterSelected: 'bg-lime-600 text-lime-50',
    filterUnselected: 'bg-lime-900/50 text-lime-200 hover:bg-lime-800'
  },
  LITERATURE: {
    name: 'yellow',
    badge: 'bg-yellow-900 text-yellow-200',
    filterSelected: 'bg-yellow-600 text-yellow-50',
    filterUnselected: 'bg-yellow-900/50 text-yellow-200 hover:bg-yellow-800'
  },
  OTHER: {
    name: 'amber',
    badge: 'bg-amber-900 text-amber-200',
    filterSelected: 'bg-amber-600 text-amber-50',
    filterUnselected: 'bg-amber-900/50 text-amber-200 hover:bg-amber-800'
  },
  ALL: {
    name: 'gray',
    badge: 'bg-gray-700 text-gray-200',
    filterSelected: 'bg-gray-600 text-gray-50',
    filterUnselected: 'bg-gray-800 text-gray-300 hover:bg-gray-700'
  },

  // Boroughs (unchanged)
  BROOKLYN: {
    name: 'blue',
    badge: 'bg-blue-900 text-blue-200',
    filterSelected: 'bg-blue-600 text-blue-50',
    filterUnselected: 'bg-blue-900/50 text-blue-200 hover:bg-blue-800'
  },
  QUEENS: {
    name: 'indigo',
    badge: 'bg-indigo-900 text-indigo-200',
    filterSelected: 'bg-indigo-600 text-indigo-50',
    filterUnselected: 'bg-indigo-900/50 text-indigo-200 hover:bg-indigo-800'
  },
  MANHATTAN: {
    name: 'cyan',
    badge: 'bg-cyan-900 text-cyan-200',
    filterSelected: 'bg-cyan-600 text-cyan-50',
    filterUnselected: 'bg-cyan-900/50 text-cyan-200 hover:bg-cyan-800'
  },
  BRONX: {
    name: 'teal',
    badge: 'bg-teal-900 text-teal-200',
    filterSelected: 'bg-teal-600 text-teal-50',
    filterUnselected: 'bg-teal-900/50 text-teal-200 hover:bg-teal-800'
  },
  'STATEN ISLAND': {
    name: 'sky',
    badge: 'bg-sky-900 text-sky-200',
    filterSelected: 'bg-sky-600 text-sky-50',
    filterUnselected: 'bg-sky-900/50 text-sky-200 hover:bg-sky-800'
  }
};

export const getColorClasses = (key, type = 'badge') => {
  const config = COLOR_CONFIG[key] || COLOR_CONFIG.ALL;
  return config[type];
};

export const getFilterButtonClasses = (key, isSelected) => {
  const config = COLOR_CONFIG[key] || COLOR_CONFIG.ALL;
  return `px-4 py-2 rounded-full text-sm font-medium transition-colors ${
    isSelected ? config.filterSelected : config.filterUnselected
  }`;
};
