import React, { useState, useEffect } from 'react';
import { WeekNavigation } from './WeekNavigation';
import { EventList } from './EventList';
import eventsData from '../data/events.json';
import { 
  startOfWeek, 
  addWeeks, 
  subWeeks, 
  parseISO, 
  format,
  isSameWeek
} from 'date-fns';

export const WeekView = () => {
  const [events, setEvents] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [filters, setFilters] = useState({
    eventType: 'ALL',
    boro: 'ALL'
  });

  useEffect(() => {
    const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
    const nextWeekStart = addWeeks(weekStart, 1);
    
    const weekEvents = eventsData
      .map(event => ({
        ...event,
        date: parseISO(`2024-${event.date.split('/').join('-')}`)
      }))
      .filter(event => {
        return event.date >= weekStart && event.date < nextWeekStart;
      })
      .sort((a, b) => a.date - b.date);

    setEvents(weekEvents);
  }, [currentWeek]);

  const filteredEvents = events.filter(event => {
    const typeMatch = filters.eventType === 'ALL' || event.event_type === filters.eventType;
    const boroMatch = filters.boro === 'ALL' || event.boro === filters.boro;
    return typeMatch && boroMatch;
  });

  const navigateWeek = (direction) => {
    setCurrentWeek(prev => 
      direction === 'next' ? addWeeks(prev, 1) : subWeeks(prev, 1)
    );
  };

  const getWeekLabel = (date) => {
    const today = new Date();
    const nextWeek = addWeeks(today, 1);
    const lastWeek = subWeeks(today, 1);
    const weekStart = startOfWeek(date, { weekStartsOn: 1 });

    if (isSameWeek(date, today, { weekStartsOn: 1 })) {
      return 'this week';
    } else if (isSameWeek(date, nextWeek, { weekStartsOn: 1 })) {
      return 'next week';
    } else if (isSameWeek(date, lastWeek, { weekStartsOn: 1 })) {
      return 'last week';
    } else {
      return `week of ${format(weekStart, 'MMMM d, yyyy')}`;
    }
  };

  return (
    <>
      <WeekNavigation 
        weekLabel={getWeekLabel(currentWeek)}
        onPrevious={() => navigateWeek('prev')}
        onNext={() => navigateWeek('next')}
        filters={filters}
        setFilters={setFilters}
        eventCount={filteredEvents.length}
      />
      <EventList events={filteredEvents} />
    </>
  );
};
