import React, { useState, useEffect } from 'react';
import { format, eachDayOfInterval, startOfWeek, endOfWeek, isAfter, startOfDay, isSameDay } from 'date-fns';
import { EventCard } from './EventCard';
import { ChevronDown, ChevronUp } from 'lucide-react';

export const EventList = ({ events }) => {
  const firstEventDate = events.length > 0 ? events[0].date : new Date();
  const weekStart = startOfWeek(firstEventDate, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
  const weekDays = eachDayOfInterval({ start: weekStart, end: weekEnd });
  const today = new Date();

  // Group events by date
  const groupedEvents = events.reduce((groups, event) => {
    const dateKey = format(event.date, 'yyyy-MM-dd');
    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }
    groups[dateKey].push(event);
    return groups;
  }, {});

  const [expandedDays, setExpandedDays] = useState({});
  const [userExpandedStates, setUserExpandedStates] = useState({});

  useEffect(() => {
    const today = startOfDay(new Date());
    const newExpandedStates = weekDays.reduce((acc, date) => {
      const dateKey = format(date, 'yyyy-MM-dd');
      acc[dateKey] = isAfter(date, today) || format(date, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd');
      return acc;
    }, {});
    setExpandedDays(newExpandedStates);
    setUserExpandedStates({});
  }, [weekStart.toString()]);

  const toggleDay = (dateKey) => {
    setUserExpandedStates(prev => ({
      ...prev,
      [dateKey]: !getIsExpanded(dateKey)
    }));
  };

  const getIsExpanded = (dateKey) => {
    return userExpandedStates[dateKey] !== undefined 
      ? userExpandedStates[dateKey] 
      : expandedDays[dateKey];
  };

  return (
    <div className="space-y-6 font-light mb-16"> {/* Added margin-bottom here */}
      {weekDays.map(date => {
        const dateKey = format(date, 'yyyy-MM-dd');
        const dayEvents = groupedEvents[dateKey] || [];
        const isExpanded = getIsExpanded(dateKey);
        const hasEvents = dayEvents.length > 0;
        const isPastDay = !isAfter(date, startOfDay(new Date()));
        const isToday = isSameDay(date, today);
        
        return (
          <div key={dateKey} className="border-t border-neutral-700/50 pt-4">
            <button 
              onClick={() => toggleDay(dateKey)}
              className={`w-full flex items-center justify-between mb-4 group ${
                hasEvents ? 'cursor-pointer hover:opacity-80' : 'cursor-default'
              } ${isPastDay ? 'opacity-70' : ''}`}
              disabled={!hasEvents}
            >
              <div className="flex items-center gap-4">
                <div className={`text-3xl font-light ${
                  isToday ? 'text-[#a891f5]' : 'text-stone-500'
                }`}>
                  {format(date, 'd')}
                </div>
                <div className="text-lg uppercase tracking-wide text-stone-300 flex items-center gap-3">
                  {format(date, 'EEEE')}
                  {hasEvents && (
                    <span className="text-sm text-stone-500">
                      {dayEvents.length} {dayEvents.length === 1 ? 'event' : 'events'}
                    </span>
                  )}
                </div>
              </div>
              {hasEvents && (
                <div className="text-stone-500">
                  {isExpanded ? (
                    <ChevronUp className="w-5 h-5" />
                  ) : (
                    <ChevronDown className="w-5 h-5" />
                  )}
                </div>
              )}
            </button>
            
            {hasEvents && (
              <div 
                className={`space-y-4 transition-all duration-300 ease-in-out overflow-hidden ${
                  isExpanded ? 'opacity-100 max-h-[5000px]' : 'opacity-0 max-h-0'
                }`}
              >
                {dayEvents.map((event, index) => (
                  <EventCard key={event.id || index} event={event} />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default EventList;
