import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { getFilterButtonClasses } from '../utils/colorConfig';

export const WeekNavigation = ({ weekLabel, onPrevious, onNext, filters, setFilters, eventCount}) => {
  const eventTypes = ['ALL', 'FOOD', 'CLOTHES', 'ART', 'MUSIC', 'POLITICS', 'EDUCATION', 'MARKET', 'LITERATURE', 'OTHER'];
  const boros = ['ALL', 'BROOKLYN', 'QUEENS', 'MANHATTAN'];

  const getEventCountText = () => {
    const plural = eventCount === 1 ? 'event' : 'events';
    return `${eventCount} ${plural}`;
  };

  return (
    <div className="space-y-6 mb-8">
      <div className="flex flex-col">
        <h1 className="text-3xl font-light text-stone-100 mb-3">
          nyc popups & happenings
        </h1>
      </div>
      {/* Filter Section */}
      <div className="space-y-3">
        {/* Event Types */}
        <div>
          <div className="text-xs font-medium text-stone-600 mb-2">
            event type
          </div>
          <div className="flex flex-wrap gap-2">
            {eventTypes.map(type => (
              <button
                key={type}
                onClick={() => setFilters(prev => ({ ...prev, eventType: type }))}
                className={getFilterButtonClasses(type, filters.eventType === type)}
              >
                {type}
              </button>
            ))}
          </div>
        </div>

        {/* Boroughs */}
        <div>
          <div className="text-xs font-medium text-stone-600 mb-2">
            boro
          </div>
          <div className="flex flex-wrap gap-2">
            {boros.map(boro => (
              <button
                key={boro}
                onClick={() => setFilters(prev => ({ ...prev, boro: boro }))}
                className={getFilterButtonClasses(boro, filters.boro === boro)}
              >
                {boro}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Week Navigation - Updated with arrow buttons */}
      <div className="relative">
        <div className="absolute left-0 top-1/2 -translate-y-1/2">
          <button
            onClick={onPrevious}
            className="p-2 text-stone-500 hover:text-stone-400 transition-colors rounded-full hover:bg-stone-800/50"
            aria-label="Previous week"
          >
            <ChevronLeft size={20} />
          </button>
        </div>

        <h1 className="text-2xl font-light text-stone-500 text-center px-20">
          {getEventCountText()} {weekLabel.toLowerCase()}
        </h1>

        <div className="absolute right-0 top-1/2 -translate-y-1/2">
          <button
            onClick={onNext}
            className="p-2 text-stone-500 hover:text-stone-400 transition-colors rounded-full hover:bg-stone-800/50"
            aria-label="Next week"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};
