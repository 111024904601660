import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { EventCard } from './EventCard';
import eventsData from '../data/events.json';
import { parseISO, format } from 'date-fns';

export const EventView = () => {
  const { link_hash } = useParams();
  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    const foundEvent = eventsData.find(e => e.link_hash === link_hash);
    if (foundEvent) {
      setCurrentEvent({
        ...foundEvent,
        date: parseISO(`2024-${foundEvent.date.split('/').join('-')}`)
      });
    }
  }, [link_hash]);

  if (!currentEvent) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl text-stone-100 mb-4">Event not found</h2>
        <Link 
          to="/"
          className="text-[#a891f5] hover:text-[#a891f5]/80 inline-flex items-center gap-2"
        >
          <ChevronLeft className="w-4 h-4" />
          Back to events
        </Link>
      </div>
    );
  }

  const monthAndDate = format(currentEvent.date, 'MMM d').toUpperCase();

  return (
    <div className="py-6">
      <Link 
        to="/"
        className="inline-flex items-center gap-2 text-stone-400 hover:text-stone-300 mb-6"
      >
        <ChevronLeft className="w-4 h-4" />
        more events
      </Link>
      
      <div className="border-t border-neutral-700/50 pt-4">
        <div className="flex items-center gap-4 mb-4">
          <div className="text-3xl font-light text-[#a891f5]">
            {monthAndDate}
          </div>
          <div className="text-lg uppercase tracking-wide text-stone-300">
            {format(currentEvent.date, 'EEEE')}
          </div>
        </div>
        <EventCard event={currentEvent} />
      </div>
    </div>
  );
};
