import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { WeekView } from './components/WeekView';
import { EventView } from './components/EventView';
import { Footer } from './components/Footer';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';

const App = () => {
  useEffect(() => {
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <BrowserRouter>
      <div className="min-h-screen flex flex-col bg-neutral-900">
        <Header />
        <main className="flex-grow container mx-auto px-4">
          <Routes>
            <Route path="/" element={<WeekView />} />
            <Route path="/e/:link_hash" element={<EventView />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
