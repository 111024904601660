export const Header = () => {
  return (
    <header className="bg-neutral-900 py-8 mb-8">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-start">
          <div className="w-[44rem]">
            <NeonLogo />
          </div>
        </div>
      </div>
    </header>
  );
};

const NeonLogo = () => (
  <div className="relative w-full">
    <svg viewBox="0 0 900 160" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
      <defs>
        {/* Inner glow */}
        <filter id="inner-glow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur"/>
          <feOffset dx="0" dy="0"/>
          <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feFlood floodColor="#a891f5" floodOpacity="0.9"/>
          <feComposite operator="in" in2="SourceAlpha"/>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>

        {/* Outer glow with multiple layers */}
        <filter id="neon-glow" x="-50%" y="-50%" width="200%" height="200%">
          {/* Distant blur */}
          <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur3"/>
          <feFlood floodColor="#9373f3" floodOpacity="0.4" result="color3"/>
          <feComposite operator="in" in="color3" in2="blur3" result="glow3"/>

          {/* Medium blur */}
          <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur2"/>
          <feFlood floodColor="#9373f3" floodOpacity="0.6" result="color2"/>
          <feComposite operator="in" in="color2" in2="blur2" result="glow2"/>

          {/* Close blur */}
          <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur1"/>
          <feFlood floodColor="#9373f3" floodOpacity="0.8" result="color1"/>
          <feComposite operator="in" in="color1" in2="blur1" result="glow1"/>

          <feMerge>
            <feMergeNode in="glow3"/>
            <feMergeNode in="glow2"/>
            <feMergeNode in="glow1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      
      {/* Background glow */}
      <text x="0" y="50%" 
            textAnchor="start" 
            dominantBaseline="middle"
            className="text-9xl font-bold uppercase"
            fill="#9373f3"
            filter="url(#neon-glow)"
            style={{
              fontFamily: "'Helvetica Neue', Arial, sans-serif",
              opacity: 0.7,
            }}>
        POPOUT.NYC
      </text>
      
      {/* Main text with tube effect */}
      <text x="0" y="50%" 
            textAnchor="start" 
            dominantBaseline="middle"
            className="text-9xl font-bold uppercase neon-glow"
            fill="#a891f5" 
            // fill="#ffffff" 
            filter="url(#inner-glow)"
            style={{
              fontFamily: "'Helvetica Neue', Arial, sans-serif",
              opacity: 0.98,
            }}>
        POPOUT.NYC
      </text>
    </svg>
  </div>
);
