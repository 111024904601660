/**
 * Formats a 24-hour time string to 12-hour format with AM/PM
 * @param {string} time - Time in HH:mm format (24-hour)
 * @returns {string} Time in 12-hour format with AM/PM
 */
export const formatDisplayTime = (time) => {
  if (!time) return '';
  const [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
  const period = hours >= 12 ? 'PM' : 'AM';
  const displayHours = hours % 12 || 12;
  return `${displayHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

/**
 * Formats a date and time for ICS file format, accounting for Eastern Time
 * @param {Date} date - JavaScript Date object
 * @param {string} timeStr - Time in HH:mm format
 * @returns {string} Formatted date-time string in ICS format
 */
export const formatICSDate = (date, timeStr) => {
  const [hours, minutes] = timeStr.split(':').map(num => parseInt(num, 10));
  
  // Create new date object to avoid modifying the original
  const eventDate = new Date(date);
  eventDate.setHours(hours, minutes, 0, 0);
  
  // Format to UTC string and clean it up to match ICS format
  return eventDate.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
};

/**
 * Calculates end time based on start time
 * @param {string} startTime - Start time in HH:mm format
 * @param {string|null} endTime - End time in HH:mm format or null
 * @returns {string} End time in HH:mm format
 */
const calculateEndTime = (startTime, endTime) => {
  if (endTime) return endTime;
  
  // If no end time, add 4 hours to start time
  const [hours, minutes] = startTime.split(':').map(num => parseInt(num, 10));
  let endHours = hours + 4;
  
  // Handle day rollover
  if (endHours >= 24) {
    endHours = endHours - 24;
  }
  
  return `${endHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

/**
 * Creates a Google Calendar URL for an event
 * @param {Object} event - Event object containing title, date, times, location, and description
 * @returns {string} Google Calendar URL
 */
export const createGoogleCalendarUrl = (event) => {
  const startTime = event.start_time;
  const endTime = calculateEndTime(event.start_time, event.end_time);

  // Format date to YYYYMMDD
  const formattedDate = event.date.toISOString().split('T')[0].replace(/-/g, '');

  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${
    encodeURIComponent(event.event_title)
  }&details=${
    encodeURIComponent(event.items_offered || '')
  }&dates=${formattedDate}T${
    startTime.replace(':', '')
  }00/${formattedDate}T${
    endTime.replace(':', '')
  }00&location=${
    encodeURIComponent(`${event.venue_name} - ${event.address}`)
  }`;
};

/**
 * Creates ICS file content for an event
 * @param {Object} event - Event object containing title, date, times, location, and description
 * @returns {string} ICS file content
 */
export const createICSContent = (event) => {
  const startTime = event.start_time;
  const endTime = calculateEndTime(event.start_time, event.end_time);
  
  // Format dates for ICS file
  const dtStart = formatICSDate(event.date, startTime);
  const dtEnd = formatICSDate(event.date, endTime);
  
  // Generate unique identifier for the event
  const uid = `${dtStart}-${event.event_title.replace(/\s/g, '-')}@yourdomain.com`;
  
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//Your Company//Your App//EN',
    'CALSCALE:GREGORIAN',
    'METHOD:PUBLISH',
    'BEGIN:VEVENT',
    `UID:${uid}`,
    `DTSTAMP:${formatICSDate(new Date(), '00:00')}`,
    `DTSTART:${dtStart}`,
    `DTEND:${dtEnd}`,
    `SUMMARY:${event.event_title.replace(/[,\\;]/g, '\\$&')}`,
    `DESCRIPTION:${(event.items_offered || '').replace(/[,\\;]/g, '\\$&')}`,
    `LOCATION:${(event.venue_name + ' - ' + event.address).replace(/[,\\;]/g, '\\$&')}`,
    'END:VEVENT',
    'END:VCALENDAR'
  ].join('\r\n');
};

export const downloadICSFile = (event) => {
  const icsContent = createICSContent(event);
  
  const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
  const url = window.URL.createObjectURL(blob);
  
  const link = document.createElement('a');
  link.href = url;
  link.download = `${event.event_title.toLowerCase().replace(/\s+/g, '-')}.ics`;
  document.body.appendChild(link);
  link.click();
  
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
